
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class VrErrorModal extends Vue {
  private dialog = false;
  public showModal(): void {
    this.dialog = true;
  }

  private closeModal() {
    this.dialog = false;
  }
}
