
import { Component, Ref, Vue } from "vue-property-decorator";
import ILesson from "@/types/lesson";
import { getLesson, getNextLesson, submitLessonResults } from "@/api/api";
import TheLoader from "@/components/TheLoader.vue";
import EducationCardTemplate from "@/components/EducationCardTemplate.vue";
import LessonContent from "@/components/LessonContent.vue";
import VrErrorModal from "@/components/VrErrorModal.vue";
import BackButton from "@/components/BackButton.vue";

@Component({
  components: {
    BackButton,
    VrErrorModal,
    LessonContent,
    EducationCardTemplate,
    TheLoader,
  },
})
export default class LessonPage extends Vue {
  @Ref("vrModal") private readonly vrModal!: VrErrorModal;

  private lesson: ILesson | null = null;
  private isLoading = true;
  private canRunVR = false;
  private courseID = "";

  private async getLesson() {
    this.isLoading = true;
    this.lesson = null;

    const id = this.$route.params.lessonId;
    try {
      this.lesson = await getLesson(id);
      if (!this.canRunVR) {
        this.vrModal.showModal();
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  private async updateContent() {
    this.courseID = this.$route.params.courseId;

    this.canRunVR = await ("xr" in window.navigator &&
      (<XRSystem>window.navigator.xr).isSessionSupported("immersive-vr"));

    await this.getLesson();
    if (!this.lesson || !this.lesson.id) {
      return;
    }

    await submitLessonResults(this.lesson.id);
  }

  mounted(): void {
    this.updateContent();
  }

  private onStartLesson() {
    if (this.lesson) {
      window.location.replace(`/vr/?lessonID=${this.lesson.id}`);
    }
  }

  private async nextLesson(): Promise<void> {
    if (!this.lesson || !this.lesson.nextLessonID) {
      return;
    }

    try {
      const { id } = await getLesson(`${this.lesson.nextLessonID}`);
      const { courseId } = this.$route.params;

      await this.$router.push(`/courses/${courseId}/lessons/${id}`);
      await this.updateContent();
    } catch (e) {
      console.error(e);
    }
  }
}
