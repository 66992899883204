import IBaseEducation from "@/types/IBaseEducation";

export const lessonContentTypes = ["pdf", "video", "audio", "image"] as const;

export type LessonContentTypes = typeof lessonContentTypes[number];

export default interface ILesson extends IBaseEducation {
  courseID?: number;
  lessonID?: number;
  complete?: boolean;
  available?: boolean;
  anomalyHints: boolean;
  video?: string;
  markupVideo?: string;
  events?: unknown[];
  public?: boolean;
  index: number;
  nextLessonID: number | null;
  showRestartButton: boolean;
  anomalyFailSelectWarningAmount: number;
  lessonClickLimit: number;
  anomalyFailSelectWarningMessage: string;
  forbidExit: boolean;
}
