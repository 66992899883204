
import ILesson, {
  LessonContentTypes,
  lessonContentTypes,
} from "@/types/lesson";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class LessonContent extends Vue {
  @Prop({ required: true }) private readonly lesson!: ILesson;

  private get contentType(): LessonContentTypes | "" {
    const type = lessonContentTypes.find((el) => {
      //@ts-ignore
      return !!this.lesson[el];
    });

    return type || "";
  }

  private get content(): string | undefined {
    if (this.contentType === "") {
      return "";
    }
    
    //@ts-ignore
    return this.lesson[this.contentType];
  }
}
